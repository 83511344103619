@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&family=Roboto:wght@400;500;700;900&display=swap");

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "plus jakarta sans", sans-serif;
  text-transform: none !important;
}

body,
p,
span,
a,
label,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "plus jakarta sans", sans-serif;
  font-weight: 500;
}